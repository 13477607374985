import { useCallback, useEffect, useRef } from "react";

import { SessionLogCommand } from "@amzn/necco-foyer-javascript-sdk";

import { getFoyerKeepAliveClient } from "~/clients";

import { Message, SessionLogContext } from "./session-log-context";

interface SessionLogProviderProps {
  children: React.ReactNode;
}

interface ServiceWorkerEventData {
  clientId: string;
  url: string;
  startTime: number;
  method: string;
}

const sendMessages = (messages: Message[]) => {
  const sessionLogCommand = new SessionLogCommand({
    sessionId: window.SESSION_STATE?.sessionId,
    messages: messages.map((message) => JSON.stringify(message)),
  });
  void getFoyerKeepAliveClient().send(sessionLogCommand);
};

export const BATCH_INTERVAL = 30 * 1000;
export const MAX_BATCH_SIZE = 20;

export const SessionLogProvider = ({ children }: SessionLogProviderProps) => {
  const messages = useRef<Message[]>([]);
  const interval = useRef<number>(0);

  const flushMessages = useCallback(() => {
    if (messages.current.length === 0) {
      return;
    }
    sendMessages(messages.current);
    messages.current = [];
  }, []);

  const reportMessage = useCallback(
    (message: Message) => {
      messages.current.push(message);
      if (messages.current.length === MAX_BATCH_SIZE) {
        flushMessages();
        clearInterval(interval.current);
        interval.current = window.setInterval(flushMessages, BATCH_INTERVAL);
      }
    },
    [flushMessages],
  );

  useEffect(() => {
    if (interval.current === 0) {
      interval.current = window.setInterval(flushMessages, BATCH_INTERVAL);
    }

    const flushMessagesIfHidden = () => {
      if (document.visibilityState === "hidden") {
        flushMessages();
      }
    };

    document.addEventListener("visibilitychange", flushMessagesIfHidden);

    return () => {
      document.removeEventListener("visibilitychange", flushMessagesIfHidden);
    };
  }, [flushMessages]);

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.onmessage = (event) => {
        const eventData = event.data as ServiceWorkerEventData;

        reportMessage({
          S3Log: {
            InstanceId: window.INSTANCE_ID,
            Url: eventData.url,
            StartTime: eventData.startTime,
            Method: eventData.method,
          },
        });
      };
    }
  }, [reportMessage]);

  return (
    <SessionLogContext.Provider value={{ reportMessage }}>
      {children}
    </SessionLogContext.Provider>
  );
};
