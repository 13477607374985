import "@aws-amplify/ui-react-storage/styles.css";
import "@aws-amplify/ui-react-storage/storage-browser-styles.css";

import { useTranslation } from "react-i18next";

import { Alert, Flex, Link } from "@aws-amplify/ui-react";
import {
  createStorageBrowser,
  elementsDefault,
} from "@aws-amplify/ui-react-storage/browser";

import { AmplifyHeader } from "./amplify-header";
import { storageBrowserConfig } from "./managed-auth-adapter";

const { StorageBrowser } = createStorageBrowser({
  elements: elementsDefault,
  config: storageBrowserConfig,
});

/**
 * Provides a configured Amplify StorageBrowser with managed auth. The managed auth adapter
 * allows us to provide StorageBrowser with the credentials available on the page via our
 * SessionStateData. The elementsDefault configured as the StorageBrowser's elements are
 * the default Amplify elements for hydrating the StorageBrowser UI; the default is
 * unstyled basic HTML.
 */
export const StorageBrowserContent = () => {
  const { t } = useTranslation();

  if (window.SESSION_STATE?.errorMessage) {
    return (
      <Alert heading={window.SESSION_STATE.errorMessage} variation="error">
        <Link href="/session/create">{t("error-message.new-session")}</Link>
      </Alert>
    );
  }

  /**
   * In order for StrorageBrowser's table to have a pinned header, its container needs to
   * have a fixed height. The second value in the below calculation is the total height of
   * our application's header component; this value will need to be updated in the event
   * that we make changes that affect the header's height.
   *
   */
  const flexHeight = "calc(100vh - 74px)";

  return (
    <>
      <AmplifyHeader />
      <Flex direction="column" height={flexHeight}>
        <StorageBrowser />
      </Flex>
    </>
  );
};
