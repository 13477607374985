import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Flex,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  Text,
  View,
} from "@aws-amplify/ui-react";

import { TITLE } from "~/constants";

declare global {
  interface Window {
    broadcastSessionStateUpdate?(sessionId: string): void;
  }
}

export const AmplifyHeader = () => {
  const { t } = useTranslation();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuOpenChange = (open: boolean) => {
    setIsMenuOpen(open);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const logoSrc: string | undefined = import.meta.env.DEV
    ? (import.meta.env.VITE_LOGO_URL ?? undefined)
    : (window.SESSION_STATE?.logoUrl ?? undefined);

  const getLogoAltTextFromTitle = (title: string) => `${title} Logo`;

  const title: string = import.meta.env.DEV
    ? (import.meta.env.VITE_APP_TITLE ?? TITLE)
    : (window.SESSION_STATE?.title ?? TITLE);

  const logoAltText = getLogoAltTextFromTitle(title);

  const userNickName: string | undefined = import.meta.env.DEV
    ? import.meta.env.VITE_USER_NICKNAME
    : (window.SESSION_STATE?.userNickName ?? undefined);

  useEffect(() => {
    document.title = window.SESSION_STATE?.title ?? "Transfer Web App";
  }, []);

  return (
    <nav
      data-height="100%"
      aria-labelledby="header-flex"
      data-testid="header-container"
    >
      <Flex
        as="header"
        id="header-flex"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        padding="1rem"
        aria-label={t("header.main-navigation-label")}
      >
        <View
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            height: "42px",
            width: "300px",
          }}
          data-testid="logo-container"
          aria-label={t("header.app-logo-label")}
        >
          {logoSrc ? (
            <Image
              src={logoSrc}
              alt={logoAltText}
              style={{
                height: "auto",
                maxHeight: "100%",
                width: "auto",
                maxWidth: "auto",
                verticalAlign: "middle",
                objectFit: "contain",
              }}
              data-testid="logo-image"
            />
          ) : (
            <Text fontWeight={700} isTruncated data-testid="logo-default-text">
              {title}
            </Text>
          )}
        </View>
        <Flex direction="row" gap="1rem" alignItems="center">
          <Menu
            isOpen={isMenuOpen}
            onOpenChange={handleMenuOpenChange}
            menuAlign="end"
            trigger={
              <MenuButton
                id="click-menu"
                aria-label={t("header.user-name-button-label")}
                aria-expanded={isMenuOpen}
                aria-haspopup={true}
                data-testid="user-menu-button"
              >
                <Flex
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  maxWidth="300px"
                >
                  <Icon
                    viewBox={{ width: 16, height: 16 }}
                    aria-hidden={true}
                    paths={[
                      {
                        d: "M8 7c1.66 0 3-1.34 3-3S9.66 1 8 1 5 2.34 5 4s1.34 3 3 3Z",
                        strokeWidth: "2px",
                        fill: "transparent",
                        stroke: "currentColor",
                      },
                      {
                        d: "M2 16v-3c0-1.66 1.34-3 3-3h6c1.66 0 3 1.34 3 3v3",
                        strokeWidth: "2px",
                        strokeLinejoin: "round",
                        fill: "transparent",
                        stroke: "currentColor",
                      },
                    ]}
                  />
                  <Text isTruncated data-testid="user-nickname-text">
                    {userNickName}
                  </Text>
                  <Icon
                    viewBox={{ width: 16, height: 16 }}
                    style={{
                      transform: `rotate(${String(isMenuOpen ? 180 : 0)}deg)`,
                    }}
                    aria-hidden={true}
                    paths={[
                      {
                        d: "m8 11 4-6H4l4 6Z",
                        strokeWidth: "2px",
                        strokeLinejoin: "round",
                        fill: "currentColor",
                        stroke: "currentColor",
                      },
                    ]}
                  />
                </Flex>
              </MenuButton>
            }
          >
            <MenuItem
              onClick={() => {
                closeMenu();
                if (window.broadcastSessionStateUpdate) {
                  window.broadcastSessionStateUpdate("");
                }
                window.location.href = "/session/logout";
              }}
              role="menuitem"
              data-testid="sign-out-menu-item"
            >
              {t("header.sign-out-menu-item-text")}
            </MenuItem>
          </Menu>
        </Flex>
      </Flex>
    </nav>
  );
};
