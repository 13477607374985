import { FetchHttpHandler } from "@smithy/fetch-http-handler";
import { AwsCredentialIdentity, Provider } from "@smithy/types";

import {
  FoyerWeb,
  FoyerWebClientConfig,
} from "@amzn/necco-foyer-javascript-sdk";

import { fromLocalDevelopmentSessionCredentials } from "./local-development-session-credentials-provider";
import { fromSessionCredentials } from "./session-credentials-provider";

export * from "./session-credentials-provider";
export * from "./local-development-session-credentials-provider";

interface Clients {
  foyer: FoyerWeb;
  foyerKeepAlive: FoyerWeb;
}

const registry = {} as Partial<Clients>;

const config = {
  region: window.SESSION_STATE?.region,
  credentials: fromSessionCredentials() as
    | AwsCredentialIdentity
    | Provider<AwsCredentialIdentity>,
};

if (import.meta.env.DEV) {
  config.region = import.meta.env.VITE_REGION ?? "us-east-1";
  config.credentials = fromLocalDevelopmentSessionCredentials();
}

const foyerWebClientConfig: FoyerWebClientConfig = {
  endpoint:
    import.meta.env.VITE_FOYER_ENDPOINT ??
    `${window.location.protocol}//${window.location.host}`,
  region: config.region,
  token: { token: "" },
};

export const getFoyerWebClient = () => {
  if (!registry.foyer) {
    registry.foyer = new FoyerWeb(foyerWebClientConfig);
  }
  return registry.foyer;
};

// https://developer.mozilla.org/en-US/docs/Web/API/Navigator/sendBeacon
// https://developer.mozilla.org/en-US/docs/Web/API/fetch#keepalive
// keepAlive parameter is equivalent to sendBeacon
const keepAliveFetch = new FetchHttpHandler({ keepAlive: true });

export const getFoyerKeepAliveClient = () => {
  if (!registry.foyerKeepAlive) {
    registry.foyerKeepAlive = new FoyerWeb({
      ...foyerWebClientConfig,
      requestHandler: keepAliveFetch,
    });
  }
  return registry.foyerKeepAlive;
};
