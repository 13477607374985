import "~/i18n";
import "@aws-amplify/ui-react/styles.css";
import "./style.css";

import React from "react";
import { createRoot } from "react-dom/client";

import { NotificationProvider, StorageBrowserContent } from "~/components";
import { lazyLoadLocale } from "~/i18n";
import { SessionLogProvider } from "~/telemetry";

void (await lazyLoadLocale());

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <SessionLogProvider>
      <NotificationProvider>
        <StorageBrowserContent />
      </NotificationProvider>
    </SessionLogProvider>
  </React.StrictMode>,
);
