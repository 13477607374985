import { createContext, useContext } from "react";

export interface Message {
  [key: string]: string | number | Message;
}

interface SessionLogContext {
  reportMessage: (message: Message) => void;
}

export const SessionLogContext = createContext<SessionLogContext>({
  reportMessage: () => undefined,
} as SessionLogContext);

export const useSessionLog = () => useContext(SessionLogContext);
