import enMessages from "@cloudscape-design/components/i18n/messages/all.en.json";

import i18n from ".";

const DEFAULT_LOCALE = "en";
const DEFAULT_MESSAGES = [enMessages];

const SUPPORTED_LOCALES = ["en"];

export const lazyLoadLocale = async () => {
  const locale = i18n.language;

  if (!SUPPORTED_LOCALES.includes(locale)) {
    return DEFAULT_MESSAGES;
  }

  if (locale === DEFAULT_LOCALE) {
    return DEFAULT_MESSAGES;
  }

  try {
    i18n.addResourceBundle(
      locale,
      "translation",
      {
        ...(await import(`./messages/${DEFAULT_LOCALE}.json`)),
        ...(await import(`./messages/${locale}.json`)),
      },
      true,
    );

    void i18n.changeLanguage(locale);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return (
      (
        await import(
          /* @vite-ignore */ `@cloudscape-design/components/i18n/messages/all.${locale}.json`
        )
      )?.default || DEFAULT_MESSAGES // eslint-disable-line @typescript-eslint/no-unsafe-member-access
    );
  } catch (err) {
    return DEFAULT_MESSAGES;
  }
};
