import { createManagedAuthAdapter } from "@aws-amplify/ui-react-storage/browser";

const getCredentialsFromSessionData = () => {
  if (import.meta.env.DEV) {
    const expiration = new Date();
    const hoursFromNow = import.meta.env.VITE_CREDS_EXPIRATION_IN_HOURS ?? 1;
    expiration.setHours(expiration.getHours() + hoursFromNow);

    return {
      credentials: {
        accessKeyId: import.meta.env.VITE_AWS_ACCESS_KEY_ID as string,
        secretAccessKey: import.meta.env.VITE_AWS_SECRET_ACCESS_KEY as string,
        sessionToken: import.meta.env.VITE_AWS_SESSION_TOKEN as string,
        expiration,
      },
    };
  }

  return {
    credentials: {
      accessKeyId: window.SESSION_STATE?.sessionCredentials?.accessKeyId ?? "",
      secretAccessKey:
        window.SESSION_STATE?.sessionCredentials?.secretAccessKey ?? "",
      sessionToken:
        window.SESSION_STATE?.sessionCredentials?.sessionToken ?? "",
      expiration: new Date(
        window.SESSION_STATE?.sessionCredentials?.expires ?? new Date(),
      ),
    },
  };
};

const getRegionFromSessionData = () => {
  if (import.meta.env.DEV) {
    return import.meta.env.VITE_REGION ?? "us-east-1";
  }

  return window.SESSION_STATE?.region ?? "";
};

const getAccountIdFromSessionData = () => {
  if (import.meta.env.DEV) {
    return import.meta.env.VITE_DEV_ACCOUNT_ID ?? "";
  }

  return window.SESSION_STATE?.accountIds &&
    window.SESSION_STATE.accountIds.length > 0
    ? window.SESSION_STATE.accountIds[0]
    : "";
};

const getS3ControlEndpointFromSessionData = () => {
  if (import.meta.env.DEV) {
    return import.meta.env.VITE_S3_CONTROL_ENDPOINT ?? "";
  }
  return window.SESSION_STATE?.endpoints?.control ?? "";
};
const getS3EndpointFromSessionData = () => {
  if (import.meta.env.DEV) {
    return import.meta.env.VITE_S3_ENDPOINT ?? "";
  }
  return window.SESSION_STATE?.endpoints?.data ?? "";
};

const managedAuthAdapter = createManagedAuthAdapter({
  credentialsProvider: async () => {
    return Promise.resolve(getCredentialsFromSessionData());
  },
  region: getRegionFromSessionData(),
  accountId: getAccountIdFromSessionData(),
  registerAuthListener: () => {
    return;
  },
  customEndpoint: getS3ControlEndpointFromSessionData(),
});

export const storageBrowserConfig = {
  ...managedAuthAdapter,
  customEndpoint: getS3EndpointFromSessionData(),
};
