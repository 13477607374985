import { createContext, ReactNode, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { FlashbarProps } from "@cloudscape-design/components";

import { v4 as uuidv4 } from "uuid";

interface NotificationProviderProps {
  children: ReactNode;
}

export type NotifyProps = Pick<
  FlashbarProps.MessageDefinition,
  "content" | "dismissible" | "type"
>;

interface NotificationContext {
  notify: (props: NotifyProps) => void;
  notifications: FlashbarProps.MessageDefinition[];
}

export const NotificationContext = createContext<NotificationContext>({
  notify: () => undefined,
  notifications: [],
});

export const NotificationProvider = ({
  children,
}: NotificationProviderProps) => {
  const { t } = useTranslation();
  const [notifications, setNotifications] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);
  const notify = useCallback(
    ({ content, dismissible, type }: NotifyProps) => {
      const id = uuidv4();
      const onDismiss = () => {
        setNotifications((notifications) =>
          notifications.filter((n) => n.id !== id),
        );
      };
      setNotifications((notifications) => [
        ...notifications,
        {
          id,
          content,
          dismissible,
          type,
          onDismiss,
          dismissLabel: t("shared.dismiss"),
        },
      ]);
    },
    [t],
  );

  return (
    <NotificationContext.Provider value={{ notify, notifications }}>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
