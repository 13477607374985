import { CredentialsProviderError } from "@smithy/property-provider";
import { AwsCredentialIdentityProvider } from "@smithy/types";

export const fromSessionCredentials =
  // eslint-disable-next-line @typescript-eslint/require-await
  (): AwsCredentialIdentityProvider => async () => {
    const accessKeyId: string | undefined =
      window.SESSION_STATE?.sessionCredentials?.accessKeyId;
    const secretAccessKey: string | undefined =
      window.SESSION_STATE?.sessionCredentials?.secretAccessKey;
    const sessionToken: string | undefined =
      window.SESSION_STATE?.sessionCredentials?.sessionToken;
    const expiry: number | undefined =
      window.SESSION_STATE?.sessionCredentials?.expires;

    if (accessKeyId && secretAccessKey) {
      return {
        accessKeyId,
        secretAccessKey,
        ...(sessionToken && { sessionToken }),
        ...(expiry && { expiration: new Date(expiry) }),
      };
    }

    throw new CredentialsProviderError("Unable to find session credentials.");
  };
